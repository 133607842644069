// https://css-irl.info/detecting-hover-capable-devices/

@import "src/GlobalValues";

$navbar-table-padding: 24px 16px;
$navbar-background-colour: #383c59;

header {
  position: fixed;
  width: 100%;
  height: $headerHeight;
  top: 0;
  z-index: 9999;
  display: flex;  // Keeps all elements in same line.

  background-color: $navbar-background-colour;
}

//@media (max-width: $phoneMaxWidth) {
//@media (hover: hover) and (pointer: fine) {
@media not all and (hover: hover) and (pointer: fine) {
  header {position: absolute;
    background-color: transparent; flex-direction: column;}
}

// Not only navbar buttons
.header-button
{
  color: $InteractHoverColor;
  height: 100%;  // Simply make the height the same as the header - No padding and calculation required.
  //display: flex;
  //align-items: center;
  text-decoration: none;
  white-space: nowrap;
  text-shadow: none;
}

a.header-button:focus {
  background-color: $InteractNeutralColor;
  color: #e0e8fc;
}

a.header-button:hover {
  background-color: #d8b5ff;
  color: #6e458f;
}

a.header-button:active {
  background-color: $InteractNeutralColor;
  color: white;
}

.header-button a:focus {@extend .header-button, :focus;}
.header-button a:hover {@extend .header-button, :hover;}
.header-button a:active {@extend .header-button, :active;}

.navbar-table{
  //width: 100%;
  //height: fit-content;  // Allows dropdown buttons to fill height.
  //height: 100%;  // Doesn't maximize to header parent??
  position: relative; top: 0;
  width: fit-content; height: inherit;
  border-spacing: 0;
  display: flex; align-items: center;
  background-color: $navbar-background-colour;
}

//.navbar-table *{background-color: $navbar-background-colour;}

.navbar-table p
{
  margin: 0;
  white-space: nowrap;
}

.navbar-item
{
  @extend .header-button;
  //padding: $navbar-table-padding;
  //$navbar-table-padding: 24px 16px;
  //padding: calc(10vh - 1em) 16px;  // Should be minus line-height
  padding: 0 1vw;
  display: flex;
  align-items: center;
  font-size: clamp(1.3rem, 1.3vw, 2.3rem);
}

a.navbar-item:focus {@extend .header-button, :focus;}
a.navbar-item:hover {@extend .header-button, :hover;}
a.navbar-item:active {@extend .header-button, :active;}

.navbar-item a:focus {@extend .header-button, :focus;}
.navbar-item a:hover {@extend .header-button, :hover;}
.navbar-item a:active {@extend .header-button, :active;}

.navbar-item-container{
  padding: 0;
}

/*.hideable-menu{
  background-color: $navbar-background-colour;
}*/

.navbar-container{
  background-color: #383c59;
  width: fit-content; height: 10vh; margin-left: auto;
  display: flex; align-items: center;
}

// TODO
// This change is the OTHER way around!
// I accidentally made it so that the media query now detected non-mobile, instead of mobile!

//@media (max-width: $phoneMaxWidth) {
//@media (hover: hover) and (pointer: fine) {
@media not all and (hover: hover) and (pointer: fine) {
  .navbar-table{
    //left: 20vw;
    width: 80vw;
    flex-direction: column;
    margin-left: auto;  // new

    background-color: #282c34;
  }

  .hideable-menu{
    transform: translateX(100%);
    transition-duration: 0.3s;
  }

  /*.navbar-table a
  {
    padding-left: 0;
    padding-right: 0;
  }*/

  .navbar-item{
    width: 100%;
    height: 100%;
    font-size: large;
  }

  a.navbar-item{
    padding: 2.5vh 2rem 2.5vh 2rem;
    justify-content: center;
  }

  // https://javascript.plainenglish.io/create-a-sliding-react-navigation-bar-441168bca19
  .hideable-menu_active{
    transform: translate(0%, 0%);
    transition-duration: 0.3s;
  }

  .navbar-container{
    height: auto;
    display: block;
  }

  .push-center-if-mobile{
    margin: auto;
  }
}

// Profile icon (and possibly button)

.profile-icon{
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 4px #000000);
  opacity: 0.5;
  transition: all 0.2s;
  //background: #00000000;
  cursor: pointer;
  height: 6vh;
}

.profile-icon:hover{
  opacity: 1;
  //background: #000;
}

.header-button:hover .profile-icon{
  opacity: 1;
}

// A failed attempt at doing a shadow
// Eventually decided to only do opacity.
/*.profile-dropdown-icon::before{
  //opacity: 1;
  background: #000;
}*/

.no-hover-bgcolor:hover {
  background-color: transparent !important;
  color: #7235a4 !important;
}

.no-hover-bgcolor:hover {
  background-color: transparent !important;
  color: #7235a4 !important;
}

.no-hover-bgcolor:active {
  background-color: transparent !important;
  color: #7235a4 !important;
}

.no-hover-bgcolor:focus {
  background-color: transparent !important;
  color: #7235a4 !important;
}

//@media (max-width: $phoneMaxWidth) {
//@media (hover: hover) and (pointer: fine) {
@media not all and (hover: hover) and (pointer: fine) {
  .profile-icon{
    height: 8vw;
  }
}