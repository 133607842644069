@import "src/GlobalValues";

$backdrop-size: min(50vh, 30vw);
//$backdrop-size: 50vh;
/*@media (max-width: $phoneMaxWidth) {
  $backdrop-size: 50vh;
}*/

//$mypath-font-size: 3vh;  // 3.5vh
$mypath-font-size: 0.7em;
//$mypath-font-size: 70%;

$iw-shape-color: rgba(255, 255, 255, 0.25);
$iw-button-color: rgba(192, 192, 192, 1);

div.my-path-stone{
  background-color: $iw-shape-color;
  width: 6vh; height: 6vh;
  position: absolute;
  border-radius: 3vh;  // Circle instead of square
}

.backdrop-size{
  width: $backdrop-size; height: $backdrop-size;
}

.backdrop-text-size{
  width: #{calc(1.3 * #{$backdrop-size})}; height: $backdrop-size;
}

.backdrop-size-expand-width{
  width: 100%; height: $backdrop-size;
}

.backdrop-missing {
  background-color: rgba(255, 0, 0, 0.25);
  transform: rotate(15deg);
}

.backdrop-generic {
  width: $backdrop-size; height: $backdrop-size;
  background-color: $iw-shape-color;
  transform: rotate(15deg);
}

/*CSS TRIANGLE!
https://codepen.io/billputer/pen/VYvVVj*/

$center-size: ($backdrop-size);
//$triangle-ratio: 0.86;  // Relevant for equilateral triangles.
$triangle-ratio: 1;

.backdrop-triple-triangle {
  /*margin: auto;
  width: 0;
  height: 0;*/

  border-bottom: #{calc(#{$center-size} * #{$triangle-ratio})} solid $iw-shape-color;
  border-left: #{($center-size / 2)} solid transparent;
  border-right: #{($center-size / 2)} solid transparent;
  transform-origin: 50% 65%;  // Relevant for ALL triangles.
}

.backdrop-image{
  width: $backdrop-size; height: $backdrop-size;
  background-size: cover;
  opacity: 0.25;
  //background-color: $iw-shape-color;
  //filter: brightness(0.4);
}

// TODO: A better way to access the public folder, without "../"?
.backdrop-adrift{ @extend .backdrop-image;
  background-image: url("../../public/Images/My-Path Icons/UI Task Icon - Battery.webp");
}

.backdrop-freecodecamp{ @extend .backdrop-image;
  background-image: url("../../public/Images/My-Path Icons/freeCodeCamp.webp");
}

.backdrop-website{ @extend .backdrop-image;
  //background-image: url("../public/Images/My-Path Icons/flag-solid.webp");
  //background-image: url("/public/Images/My-Path Icons/flag-solid.webp");
  background-image: url("../../public/Images/My-Path Icons/flag-solid.webp");
}

.backdrop-text{
  @extend .backdrop-text-size;
}

.backdrop-text p{
  font-size: $mypath-font-size;
}

.start-flag{
  width: 10vh; height: 10vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

// https://css-tricks.com/snippets/css/scale-on-hover-with-webkit-transition/
.start-flag svg{
  width: 90%; height: 90%;
  color: $iw-button-color;
  transition: all .25s ease-in-out;
  text-align: center;
  vertical-align: bottom;
}

.start-flag a{
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
.start-flag a div{
  width: inherit; height: inherit;
  background-image: url("../public/Images/React/logo192.webp");
  background-size: cover;
}
*/

// https://www.w3schools.com/css/css3_transitions.asp
.start-flag a svg:hover{
  width: 100%; height: 100%;
  transition: width .25s;
  color: rgba(255, 255, 255, 1);
  //background-color: rgba(255, 255, 255, 0.25);
}

.start-flag a svg:active{
  color: rgba(128, 128, 128, 1);
  //background-color: rgba(255, 255, 255, 0.5);
}

.mypath-arrows-holder{
  top: $headerHeight; left: 0; position: fixed;
  width: 100vw; height: calc(100vh - #{$headerHeight});
  pointer-events: none;
}

.path-arrow-button{
  $size: 15vh;
  left: calc(50% - #{$size} / 2);
  width: $size; height: $size;
  display: flex; justify-content: center;
  pointer-events: all;
}

.path-arrow-button a{
  width: 90%; height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.path-arrow-button svg{
  width: inherit; height: inherit;
  // https://stackoverflow.com/questions/68270468/what-is-the-usage-of-webkit-fill-available
  //height: -moz-available;          /* WebKit-based browsers will ignore this. */
  //height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  //height: fill-available;
  color: $iw-button-color;
  transition: all .25s ease-in-out;
  text-align: center;
  vertical-align: bottom;
  filter: drop-shadow(0 0 0.75rem black);
}

.path-arrow-button a svg:hover{
  width: 100%; height: 100%;
  transition: all .25s;
  color: rgba(192, 192, 192, 1);
  //background-color: rgba(255, 255, 255, 0.25);
}

.path-arrow-down a svg:active{
  color: rgba(64, 64, 64, 1);
  //background-color: rgba(255, 255, 255, 0.5);
}

.path-arrow-button-small{
  $size: 9vh;  // 60% of parent, which is 0.6*15vh
  left: calc(50% - #{$size} / 2);
  width: $size; height: $size;
  display: flex; justify-content: center;
  pointer-events: all;
}

// TODO it's unneeded copy paste
.path-arrow-button-small a{
  width: 90%; height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.path-arrow-button-small svg{
  width: inherit; height: inherit;
  color: $iw-button-color;
  transition: all .25s ease-in-out;
  text-align: center;
  vertical-align: bottom;
  filter: drop-shadow(0 0 0.75rem black);
}

.path-arrow-button-small a svg:hover{
  width: 100%; height: 100%;
  transition: all .25s;
  color: rgba(192, 192, 192, 1);
}

.path-arrow-down-small a svg:active{
  color: rgba(64, 64, 64, 1);
}