// I employed text responsiveness like this: https://bootcamp.uxdesign.cc/responsive-text-the-easy-way-becb22c18745

@import "src/GlobalValues";

/*--- Added by me! ---*/

/*#region BNApp*/

.App {
  text-align: center;
  color: white;
  background-color: #282c34;
  //position: fixed;  // TODO: Why'd I do this? Something relevant to mobile responsiveness.
}

.App-body {
  min-height: calc((100vh - 20vh));  // TODO: 100vh minus either topbar or bottombar or both
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

/*#endregion */

/////////////////////////////////////// SPECIFIC STUFF

/*#region Tooltip*/

// https://www.w3schools.com/css/css_tooltip.asp

// TODO OPTIONAL: .tooltip-text-cursor. When hovering over a tooltip container, the tooltip will appear below/above the cursor.
//  It's useful for tooltip containers that break into two pieces.
//  But, I'd rather have the tooltips break into two, and find another way to handle it.

.tooltip-container {
  position: relative;
  display: inline;
  border-bottom: 2px dotted $InteractNeutralColor;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  width: 16vw;
  background-color: #161a33;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  // See the w3schools link - Bottom Tooltip
  top: 100%;
  left: 50%;
  margin-left: -8vw; /* Use half of the width to center the tooltip */

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}

/*#endregion */

/*#region CoolRoundedList*/

// https://catalin.red/css3-ordered-list-styles/
ol.rounded-list {
  counter-reset: li; /* Initiate a counter */
  list-style: none; /* Remove default numbering */
  *list-style: decimal; /* Keep using default numbering for IE6/7 */
//  font: 15px 'trebuchet MS', 'lucida sans';
  padding: 0;
  margin-bottom: 4em;
  text-shadow: 0 1px 0 rgba(0,0,0,.25);

  display: flex;
  flex-direction: column;
}

.rounded-list a{
  position: relative;
  display: block;
  padding: 1em 2em;
  *padding: .4em;
  margin: 0;
  background: #ddd;
  color: #7235a4;
  text-decoration: none;
  border-radius: 50em;
  transition: all .3s ease-out;
  font-size: clamp(1rem, 1vw, 2rem);  // Responsive
  text-shadow: none;
}

.rounded-list a:hover{
  background: #eee;
}

.rounded-list a:hover:before{
//  transform: rotate(360deg);
  transform: scale(1.2);
}

.rounded-list a:before{
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: -1.3em;
  top: 50%;
  margin-top: -1.3em;
  background: $InteractNeutralColor;
  height: 2em;
  width: 2em;
  line-height: 2em;
  border: .3em solid #fff;
  text-align: center;
  font-weight: bold;
  border-radius: 2em;
  transition: all .2s ease-out;
  box-sizing: content-box;  // TODO: Maybe overriding this is bad?
}

@media (max-width: $phoneMaxWidth) {
  .rounded-list a{
    font-size: 4vw;
  }
}

/*#endregion */

/*#region MISC.*/
.MyPage{
  width: 100%;
}

.middle-vertical-part{
  position: relative; top: $headerHeight;
  display: flex;
}

@media (max-width: $phoneMaxWidth) {
  .middle-vertical-part{
    top: 0;
    display: inherit;
  }
}

.middle-inner-part{
  display: flex;
  justify-content: left;
  flex-direction: column;

  $middle-inner-part-width: 80vw;
  width: $middle-inner-part-width;
  margin: auto;
}

@media (max-width: $phoneMaxWidth) {
  .middle-inner-part{
    width: auto;
  }
}

.middle-inner-part-margin{
  $middle-inner-part-margin: 32px;  // TODO: px bad
  min-height: calc(100vh - #{$headerHeight} - 2 * #{$middle-inner-part-margin});
  margin: $middle-inner-part-margin;
}

.middle-inner-part-margin-clamped{
  $middle-inner-part-margin: 32px;  // TODO: px bad
  width: inherit;
  height: calc(100vh - #{$headerHeight} - 2 * #{$middle-inner-part-margin});
  margin: $middle-inner-part-margin;
}

@media (max-width: $phoneMaxWidth) {
  .middle-inner-part{
    $middle-inner-part-width: 80vw;
    $middle-inner-part-margin: (100vw - $middle-inner-part-width) / 2;
    $middle-inner-part-height: calc(100vh - 2 * #{$middle-inner-part-margin});  // https://stackoverflow.com/questions/17982111/sass-variable-in-css-calc-function
    //width: $middle-inner-part-width;
    margin: $middle-inner-part-margin;
    //height: $middle-inner-part-height;
  }
}



.left-space{
  width: 10vw;
}
.right-space{
  width: 10vw;
}

@media (max-width: $phoneMaxWidth) {
  .left-space{width: 0;}
  .right-space{width: 0;}
}

/*terminal-1*/
@font-face {
  font-family: 'terminal-1';
  /*src: url('webfont.eot'); /* IE9 Compat Modes */
  src: /*url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          /*url('webfont.woff') format('woff'), /* Modern Browsers */
          /*url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
          local('terminal-1'), url(./Fonts/terminal-1.ttf)  format('truetype'); /* Safari, Android, iOS */
  /*url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}

.pixel-font {
  font-family: terminal-1, serif;
}

.large-font-inside p,{
  font-size: clamp(1.5rem, 1.4vw, 3rem);
}

/*@media (max-width: $phoneMaxWidth) {
  .large-font-inside *{
    font-size: 4.5vw;
  }
}*/

article{
  margin: 50px 0 50px 0;
}

.article-title-wrapper{
  padding-top: 30px;
  padding-bottom: 30px;
}

.article-content-wrapper{
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itch-article-wrapper{
  width: 50vw;
}

.itch-article-wrapper *{
  font-family: inherit;
}

@media (max-width: $phoneMaxWidth) {
  .itch-article-wrapper{
    width: 90vw;
  }
}

.itch-article-content p{
  text-align: left;
  font-family: inherit;
  margin: 1.5em;
  line-height: 2;
}

$me-pics-size-home: 40vh;
$me-pics-size-about: 30vh;
$me-pics-size-home-small: 30vw;
$me-pics-size-about-small: 25vw;
.me-pics-size-home{
  /*First image: 58vw, calc(30vh+128px)*/
  /*Second image: 70vw, calc(40vh+128px)*/
  position: absolute;
  //picSize: $me-pics-size-home;
  width: $me-pics-size-home; height: $me-pics-size-home;
}
.me-pics-size-about{
  position: absolute;
  width: $me-pics-size-about; height: $me-pics-size-about;
}

@media (max-width: $phoneMaxWidth) {
  .me-pics-size-home{
    /*First image: 58vw, calc(30vh+128px)*/
    /*Second image: 70vw, calc(40vh+128px)*/
    //picSize: $me-pics-size-home;
    width: $me-pics-size-home-small; height: $me-pics-size-home-small;
  }

  .me-pics-size-about{
    position: absolute;
    width: $me-pics-size-about-small; height: $me-pics-size-about-small;
  }
}

.TotalShadow {
  position: fixed;
  left: 0;
  top: 10vh;
  width: 100vw;
  height: 90vh;
  margin: 0;
  background: #00000088;
  z-index: 9999;
}

.ServicesGridPanel{
  /*background-image: url("../public/Images/ServicePalette.webp"); background-size: cover;
  image-rendering: pixelated; background-repeat: round;*/
  width: 100%; height: 90vh;
}

.ServicesGridPanel a{
  display:flex;
  align-items:center;
  justify-content:center;
  color: #282c34;
  text-shadow: 0 1px 0 rgb(0 0 0 / 25%);
  font-weight: bold;
}

.ServicesPage{
  width: 100%; height: 100%;
  min-height: calc(100vh - #{$headerHeight});
}

.game-related-creation-list li{
  font-size: clamp(0.9rem, 0.9vw, 1.6rem);
}

// TODO OPTIONAL: Move to MyNavbar.scss?
// Ensuring the dropdown items have FULLY clickable rectangles.
.dropdown-item{
  $width-padding: 0.5rem;
  $height-padding: 1rem;
  padding: $height-padding $width-padding $height-padding $width-padding;
  border-radius: 0.375rem;  // Just like dropdown-menu
  font-size: clamp(0.9rem, 0.9vw, 1.4rem);
}
.dropdown-divider{ margin: 0; }
.dropdown-menu {
  $newHeight: calc(#{$headerHeight} - 1px);
  --bs-dropdown-padding-y: 0;
  transform: translate(-1px, $newHeight) !important;  // Ensures exact adjacency to the navbar.
}

.carousel .carousel-inner{
  width: 100%; height: 100%;
}

/*.carousel-inner{
  display: flex;
  align-items: center;
}*/

.social-media-container{
  display: flex;
  justify-content: space-between;
}

.social-media-container a svg{
  height: 3vw;
  aspect-ratio: 1/1;
}

@media (max-width: $phoneMaxWidth) {
  .social-media-container a svg{
    height: 7vw;
  }
}

.social-media-container-smaller a svg{
  height: 2vw;
}

// https://dev.to/oussel/how-to-use-conditional-rendering-with-animation-in-react-1k20
@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

a.testbox-link{
  color: #80ff00;
}

.unselectable{
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.service-grid-cell{
  @extend .unselectable;
  cursor: pointer;
}

.service-grid-cell-backdrop{
  @extend .unselectable
}
/*#endregion */


/////////////////////////////////////// EXPORTS

// https://stackoverflow.com/questions/52900207/how-to-use-scss-variables-into-my-react-components
/*:export{
  backdropSize: $backdrop-size;
  mePicsSizeHome: $me-pics-size-home;
  mePicsSizeAbout: $me-pics-size-about;
}*/

:export {
  headerHeight: $headerHeight;
}