@import "src/GlobalValues";

/*#region GenericStuff*/

// TODO: Be more selective with the font.
html * {
  //font-family: terminal-1, serif;
  font-family: Verdana, serif;
  letter-spacing: 2px;
}

// https://stackoverflow.com/questions/24739126/scroll-to-a-specific-element-using-html
html {
  scroll-behavior: smooth;
  background-color: #282c34;
  font-size: 62.5%;
}

body {
  background-color: #282c34;
}

h1 {
  letter-spacing: 6px;
}

h2 {
  font-size: clamp(2rem, 2.5vw, 4rem);
  // Original: fontSize: isMobile ? "6vw" : "3vw",
}

h5 {
  margin-block-start: 0.5em;
  margin-block-end: 0;
}

p {
  margin-block-start: 0.75em;
  margin-block-end: 0.75em;
  //  font-size: small;
  font-size: clamp(1.2rem, 1.2vw, 2.1rem);
}

.p-small {
  font-size: clamp(0.9rem, 0.8vw, 1.5rem);
}

/*@media (max-width: $phoneMaxWidth) {
  p
  {
    font-size: small;
  }
}*/

li {
  margin-block-start: 0.75em;
  margin-block-end: 0.75em;
  //  font-size: small;
  font-size: clamp(1.2rem, 1.2vw, 2.1rem);  // Responsive
}

a {
  color: $InteractNeutralColor;
  text-decoration: none;
  text-shadow: 0px 1px 4px black;
}

a:hover {
  color: $InteractHoverColor;
}

a:active {
  color: $InteractActiveColor;
}

ul {
  margin-block-start: 0.75em;
  margin-block-end: 0.75em;
}

button {
  border: None;
  color: $InteractNeutralColor;
  background: #ddd;
  text-shadow: 0 1px 0 rgb(0 0 0 / 25%);
  margin: 0.75em;
}

button:hover{
  background: #eee;
}

hr {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

footer {
  position: relative; top: $headerHeight;
  padding-top: 5.5vh; padding-bottom: 5.5vh;
  color: #bbb;
  background-color: #383c59;
}

// header style's at MyNavbar.scss

img.dropShadow { filter: drop-shadow(0 0 20px black); }

.unselectable {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/*#endregion */